<template>
    <section id="login-user">
        <div class="row">
            <div class="col-sm-12">
                <h3 class="title">
                    Login
                    <i class="fa fa-spinner fa-spin" v-if="loading"></i>
                </h3>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-6">
                <div :class="formGroupClass('email')">
                    <input class="form-control" :class="{'is-invalid': form.errors.has('email')}" id="email" type="email" name="email" placeholder="E-Mail" v-model="form.email" required="required"/>
                    <div class="invalid-feedback" v-if="form.errors.has('email')">
                        <strong>{{ form.errors.get('email') }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div :class="formGroupClass('password')">
                    <input class="form-control" :class="{'is-invalid': form.errors.has('password')}" id="password" type="password" name="password" placeholder="Passwort eingeben" v-model="form.password" required="required"/>
                    <div class="invalid-feedback" v-if="form.errors.has('password')">
                        <strong>{{ form.errors.get('password') }}</strong>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {

    data() {
        return {
            loading: false,
            form: new Form({
                email: null,
                password: null,
            })
        };
    },

    methods: {
        formGroupClass(name) {
            return this.form.errors.has(name) ? 'form-group has-error' : 'form-group';
        }
    },

    created() {

        //- register user
        App.event.listen('login.send', () => {
            this.loading = true;
            this.form.post('/login').then(response => {
                this.loading = false;
                App.event.fire('login.success', response);
            }).catch(errors => {
                this.loading = false;
                App.event.fire('login.failed', errors);
            });
        });

    }
}
</script>
