import offside from 'offside-js'

export default class OffCanvas {

  constructor (config = {}) {
    this.config = {
      menuSelector: config.menuSelector || '[data-role="mobile-menu"]',
      slidingElementsSelector: config.slidingElementsSelector || '#app',
      buttonsSelector: config.buttonsSelector || '[data-role="mobile-menu-toggle"]',
      beforeClose: () => this.removeOverlay(),
      beforeOpen: () => this.addOverlay(),
      ...config,
    }

    // this.init()
  }

  init () {
    this.menu = offside(this.config.menuSelector, this.config)
  }

  addOverlay () {
    const overlay = document.createElement('div')
    overlay.classList.add('mobile-overlay')
    overlay.addEventListener('click', this)
    this.overlay = overlay;

    document.body.appendChild(overlay);
  }

  removeOverlay () {
    if (this.overlay) {
      this.overlay.removeEventListener('click', this)
      document.body.removeChild(this.overlay)
      this.overlay = null
    }
  }

  handleEvent() {
    this.close()
  }

  open () {
    this.menu && this.menu.open()
  }

  close () {
    this.menu && this.menu.close()
  }

  toggle () {
    this.menu && this.menu.toggle()
  }

  destroy () {
    this.menu && this.menu.destroy()
  }
}
