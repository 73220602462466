'use strict';

import PaymentType from './components/PaymentType.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('payment-type', PaymentType);

    }
}
