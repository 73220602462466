<template>
  <div class="product-list--item">
    <div class="product-list--item-header" v-html="category"></div>
    <div class="product-list--item-content">
      <div class="align-self-start w-100">
        <a :href="url">
          <div class="product-list--item-image">
            <img v-if="image" :src="image" :alt="description" />
          </div>
          <h3 :title="description" v-html="description"></h3>
        </a>
        <template v-if="descriptionShort">
          <strong>Beschreibung:</strong>
          <p :title="descriptionShortPlain" v-html="descriptionShort"></p>
        </template>
      </div>
      <div class="align-self-end w-100">
        <div class="product-list--item-price">
          <strong>Ab € {{ price }}</strong> <small>exkl. Versandkosten</small>
        </div>
        <a :href="url" class="btn btn-blue mt-3">Bestellen</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        id: Number,
        url: String,
        price: String,
        image: String,
        category: String,
        description: String,
        descriptionShort: String,
        descriptionShortPlain: String
    }
}
</script>