<template>
    <div class="text-center">
        <button class="btn btn-lg btn-blue" type="button" @click="onClick" :disabled="loading">
            <i class="fa fa-spinner fa-spin" v-if="loading"></i>
            <slot>Registrieren</slot>
        </button>
    </div>
</template>

<script>
export default {

    data() {
        return {
            loading: false
        }
    },

    methods: {
        onClick() {
            this.loading = true;
            App.event.fire('registration.send');
        }
    },

    created() {

        App.event.listen('registration.failed', () => this.loading = false);
        App.event.listen('registration.success', response => {
            this.loading = false;
            window.location = App.url + response.redirect;
        });

    }
}
</script>
