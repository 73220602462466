/**
 * Last but not least, no web page is
 * complete without it, well in EU that is.
 */
require('cookieconsent');


/**
 * Strap that Vue back in!
 */
window.Vue = require('vue');