<template>
    <div id="wirecard-form">

        <form method="post" action="wirecard/init" ref="form">
            <input type="hidden" name="_token" :value="token" />
            <input type="hidden" name="paymentType" :value="paymentType" />
        </form>

        <!-- Credit Card payment component -->
        <template v-if="paymentType == 'CCARD'">
            <credit-card @submit="submit" :javascript-url="javascriptUrl"></credit-card>
        </template>

        <!-- Paypal payment component -->
        <template v-if="paymentType == 'PAYPAL'">
            <paypal @submit="submit"></paypal>
        </template>
        
        <!-- Sofortüberweißung payment component -->
        <template v-if="paymentType == 'SOFORTUEBERWEISUNG'">
            <sofort @submit="submit"></sofort>
        </template>

    </div>
</template>


<script>

import Sofort from './WirecardSofort.vue';
import Paypal from './WirecardPaypal.vue';
import CreditCard from './WirecardCreditCard.vue';

export default {

    components: {
        'sofort': Sofort,
        'paypal': Paypal,
        'credit-card' : CreditCard
    },

    props: {
        storageId: {
            type: [String],
            required: true
        },
        paymentType: {
            type: [String],
            required: true
        },
        javascriptUrl: {
            type: [String],
            required: false
        }
    },

    data() {

        return {
            token: null
        }

    },

    created() {

        let token = document.head.querySelector('meta[name="csrf-token"]');

        if (token) {
            this.token = token.content;
        }
    
    },

    methods: {

        submit() {
            this.$refs.form.submit();
        },

    }

}
</script>
