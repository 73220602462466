export default class Event {

    /**
     * Create a new Event instance.
     *
     */
    constructor() {

        this.vue = new Vue();

    }

    /**
     * Fires an event on vue instance.
     * 
     * @param {string} event name
     * @param {object} data 
     */
    fire(event, data = null) {

        this.vue.$emit(event, data);

    }

    /**
     * Registeres an event handler
     * 
     * @param {string} event name
     * @param {function} callback Event handler
     */
    listen(event, callback) {

        this.vue.$on(event, callback);

    }

}
