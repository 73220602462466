<template>
    <section id="payment-types">
        <div class="row">
            <div class="col-sm-12">
                <b class="title">Zahlart wählen</b>
            </div>
        </div>
        <div class="row backgroundGray">
            <div class="col-sm-6">
                <div class="checkbox">
                    <label style="cursor:pointer" title="Kreditkarte">
                        <input type="radio" name="payment" value="creditcard" checked>
                        <span><img src="/shop/images/mastercard_hrz_opt_pos_105_3x.png" height="45px"/> <img src="/shop/images/Visacard.png" height="25px"/></span>
                    </label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="checkbox">
                    <label style="cursor:pointer" title="Klarna Rechnung">
                        <input type="radio" name="payment" value="klarna">
                        <span><img src="/shop/images/klarna.svg" height="50px"/></span>
                    </label>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  
}
</script>
