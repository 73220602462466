'use strict';

import Cart from './components/Cart.vue';
import CartRow from './components/CartRow.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('cart', Cart);
        Vue.component('cart-row', CartRow);

    }
}
