/* global google */
import styles from './style'

export default class GoogleMap {
  constructor (config) {
    const defaults = {
      zoom: 15,
      locations: [],
      icon: location.origin + '/images/marker.svg',
      el: '[data-role="google-map"]',
      center: { lat: null, lng: null },
    }

    this.markers = []
    this.config = {...defaults, ...config};
    this.el = document.querySelector(this.config.el)
    this.el && this.init()
  }

  /**
   * Setup map
   */
  async init () {
    await this.api();
    const config = this.config;
    this.map = new google.maps.Map(this.el, {
      styles,
      zoom: parseInt(config.zoom || 17),
      center: new google.maps.LatLng(
        config.center.lat,
        config.center.lng
      )
    })

    config.locations.forEach((location) => {
      let marker = new google.maps.Marker({
        map: this.map,
        icon: config.icon,
        position: new google.maps.LatLng(
          parseFloat(location.lat),
          parseFloat(location.lng)
        )
      })
      this.markers.push(marker);
    })
  }

  /**
   * Load google maps API
   */
  api () {
    return new Promise((resolve) => {
      let script = document.createElement('script');
          script.onload = resolve
          script.src = `https://maps.googleapis.com/maps/api/js?key=${this.config.key}`
      document.body.appendChild(script);
    })
  }
}
