/**
 * Main javascript entry point
 * Should handle bootstrapping/starting application
 *
 * @author Matej Svajger <hello@matejsvajger.com>
 */
// import 'promise-polyfill/src/polyfill';
require("es6-symbol/implement");

import axios from 'axios';
import Application from './core/app';
import Sliders from './core/sliders';
import Form from './core/form';

/**
 * First we will load all of this project's JavaScript dependencies which
 * include jQuery, Bootstrap, bootstrap-datepicker and bootstrap-select,
 * slick-slider, magnificPopup, mmenu, parsley, sweetalert and
 * cookieconsent. This gives a great starting point for building
 * robust, powerful web applications.
 */

try {

require('./bootstrap');


/**
 * Setup some globals for objects we'll be using across application.
 */
window.Form = Form;
window.axios = axios;

window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest'
};

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
}


/**
 * Boot application
 */
window.App = new Application()
window.SLIDER_API = window.SLIDER_API || null
window.Sliders = new Sliders()
} catch (e) {
    console.log(e)
}
