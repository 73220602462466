<template>
    <tr>
        <td scope="row" v-html="name"></td>
        <td v-if="readonly" class="text-center">
            {{ item.qty }}
        </td>
        <td v-else>
            <spinner @change="updateQty" :value="item.qty" :max="item.max" :min="getMinQty(item)" :loading="loading"></spinner>
        </td>
        <td v-if="!readonly" class="text-center">
            <a href="#" @click.prevent="remove">
                <i :class="removeIcon"></i>
            </a>
        </td>
        <td>{{ total }}&nbsp;€</td>
    </tr>
</template>

<script>
import Spinner from '@Modules/spinner/components/Spinner';

export default {
    components: {
        Spinner
    },
    props: {
        item: {
            required: true
        },
        readonly: {
            default: false
        }
    },

    data() {
        return {
            loading: false,
            removing: false,
            initialized: false,
        }
    },

    methods: {

        updateQty(qty) {
            this.loading = true;
            new Form({rowId: this.item.rowId, qty:qty})
            .post('/api/cart/update')
            .then((response) => {
                App.event.fire('cart.updated', response);
                this.loading = false;
            });
        },

        getMinQty(item) {
            if (item.roseOrder) {
                return Math.ceil(
                    item.roseOrderMinimum / (item.price + item.tax)
                )
            } else {
                return 1;
            }
        },

        remove() {
            this.removing = true;
            new Form({rowId: this.item.rowId})
            .post('/api/cart/remove')
            .then((response) => {
                App.event.fire('cart.updated', response);
                this.removing = false;
            });
        }

    },

    computed: {

        total() {
            return parseFloat(this.item.total).toFixed(2).replace('.', ',');
        },

        subtotal() {
            return parseFloat(this.item.subtotal).toFixed(2).replace('.', ',');
        },

        removeIcon() {
            return this.removing ? 'fa fa-spinner fa-spin' : 'fa fa-times';
        },

        name() {
            return `
                <b>${this.item.name}</b>
                <br>
                ${this.item.options ? Object.keys(this.item.options).map(key => {
                    const value = this.item.options[key].name || this.item.options[key]
                    return key !== 'price' && value ? `<b>${key}:</b> ${value} <br>` : ''
                }, '').reduce((p, n) => p + n, '') : ''}
            `;
        }
    }


}
</script>

