'use strict';

import AddToCartButton from './components/AddToCartButton.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('add-to-cart-button', AddToCartButton);

    }
}
