<template>
    <section id="checkout">
        <form :action="processUrl" method="post">
            <input type="hidden" name="_token" :value="token" />
            <div class="row">
                <div class="col-sm-12">

                    <cart @onLoad="onCartLoad" :user="user"></cart>

                </div>
            </div>

            <user-handler :user="user" v-if="!user && initialized"></user-handler>

            <template v-else>
                <div v-if="!cartEmpty" class="row">
                    <div class="col-sm-12">
                        <div class="flower-spacer"></div>
                        <shipping-address @change="updateShipping" :user="user"></shipping-address>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <div class="flower-spacer"></div>
                        <payment-type></payment-type>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-sm-12 text-center">
                        <div class="flower-spacer"></div>
                        <button type="submit" class="btn btn-lg btn-blue" :disabled="cartEmpty">zur Kasse</button> 
                    </div>
                </div>
            </template>
        </form>
    </section>
</template>

<script>
export default {

  data() {
    return {
        initialized: false,
        cartEmpty: true,
        user: null,
    }
  },
  
  computed: {

    processUrl() {
        return App.url + '/checkout/process';
    },

    token() {
        return App.reactive.token;
    }

  },

  methods: {

        onCartLoad(items) {
            this.cartEmpty = Object.keys(items).length === 0;
        },

        fetchUser() {
            try {
            new Form().get('/api/me')
                .then(user => {
                    this.user = user;
                    this.initialized = true;
                    App.event.fire('user.logged-in', user);
                }).catch(error => {
                    this.initialized = true;
                    App.event.fire('user.logged-out', error);
                });
            } catch (e) {
                console.log(e)
            }
        },

        async updateShipping(address) {
            const zone = await new Form(address).post('/api/cart/shipping-zone')
            App.event.fire('shipping.updated', zone);
        },

    },

    created() {
        this.fetchUser();
        App.event.listen('login.success', ({ user }) => this.user = user);
        App.event.listen('registration.success', ({ user }) => this.user = user);
        App.event.listen('guest.success', ({ user }) => this.user = user);
    }

}
</script>
