<template>
    <div class="product-detail--options">
        <div class="row" v-if="errors.has('general')">
            <div class="col-md-12">
                <div class="alert alert-danger" role="alert">
                    {{ errors.get('general') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-7">
                <div class="form-group form-group-fill">
                    <label for="cardtext">Kartentext: *</label>
                    <textarea name="options[Kartentext]" v-model="options.text" id="cardtext" class="form-control" :class="{'is-invalid' : errors.has('text')}"></textarea>
                    <div class="invalid-feedback">
                        {{ errors.get('text') }}
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group" v-if="dropdown">
                    <label for="product-dropdown">{{ dropdown.name }}</label>
                    <select :name="`options[${dropdown.name}]`" v-model="options.dropdown" @change="updatePrice" id="product-dropdown" class="form-control">
                        <option v-for="(attr, key) in dropdown.attributes" :key="attr.id" :value="key" v-html="attr.name"></option>
                    </select>
                </div>
                <div class="form-group" v-if="priceInput">
                    <label for="product-date">Lieferdatum: *</label>
                    <v-datetime :errors="errors" label="Lieferdatum" v-model="options.date"></v-datetime>
                </div>
                <div class="form-group" v-if="priceInput">
                    <label for="product-price">{{ priceInput.name }}</label>
                    <spinner @change="setCustomPrice" v-model="options.price" :min="price" :step="0.1" :readonly="false" icon="fa fa-eur"></spinner>
                </div>
                <div class="form-group">
                    <label for="product-quantity">Anzahl:</label>
                    <spinner v-model="qty" :min="min" :max="max"></spinner>
                </div>
                <button @click="submit" :disabled="loading" class="btn btn-sm btn-blue w-100">
                    <i v-if="loading" class="fa fa-spin fa-spinner"></i>
                    <slot>in den Warenkorb</slot>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Spinner from '../../spinner/components/Spinner'
import VDatetime from '../../v-datetime/VDatetime'
import Errors from '../../../core/errors'
import { DateTime } from 'luxon';

export default {
    components: {
        Spinner,
        VDatetime
    },
    props: {
        pid: {
            type: [String, Number],
            required: true,
            default: null
        },
        max: {
            type: [String, Number],
            default: 100
        },
        min: {
            type: [String, Number],
            default: 1
        },
        type: {
            type: [String],
            default: 'detail'
        },
        dropdown: {
            type: [Object],
            default: null
        },
        price: {
            type: [Number],
            default: 0
        },
        priceInput: {
            type: [Object],
            default: null
        },
        roseOrder: {
            type: Boolean,
            default: false
        },
        roseOrderMinimum: {
            type: Number,
            default: 0
        },
        quantityDivider: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            qty: 1,
            loading: false,
            errors: new Errors(),
            options: {
                text: '',
                date: null,
                dropdown: 0,
                price: this.price
            }
        }
    },

    computed: {
        selectedDropdownOption() {
            return this.dropdown
                ? this.dropdown.attributes[this.options.dropdown]
                : null
        }
    },

    methods: {
        reset() {
            this.options = {
                text: '',
                dropdown: 0,
                price: this.price
            }
        },

        updatePrice () {
            const optionPrice = this.selectedDropdownOption
                ? this.selectedDropdownOption.price
                : 0;

            App.event.fire('product.price.changed', this.price + optionPrice)
        },

        setCustomPrice (price) {
            App.event.fire('product.price.changed', price)
        },

        validate () {
            let valid = true;
            const errors = {}
            this.errors.clear()

            if (this.quantityDivider) {
                if (this.qty % this.quantityDivider !== 0) {
                    errors.general = `Die Mindestabnahme beträgt ein Vielfaches von ${this.quantityDivider} Produkte.`
                    valid = false
                }
            }

            if (this.roseOrder) {
                const total = this.price * this.qty;
                if (total < this.roseOrderMinimum) {
                    errors.general = `Sie müssen mindestens im Wert von ${this.roseOrderMinimum} EUR bestellen`
                    valid = false
                }
            }

            if (this.options.text.length < 1) {
                errors.text = 'Kartentext ist ein Pflichtfeld.'
                valid = false
            }

            if (this.priceInput && this.options.date.length < 1) {
                errors.date = 'Lieferdatum ist ein Pflichtfeld.'
                valid = false
            }

            this.errors.record(errors)
            return valid;
        },

        async submit () {
            if (!this.validate()) {
                return;
            }

            this.loading = true

            let options = {
                Kartentext: this.options.text
            }

            if (this.dropdown) {
                options[this.dropdown.name] = this.selectedDropdownOption
            }

            if (this.priceInput) {
                options['price'] = this.options.price
                options['Lieferdatum'] = DateTime.fromISO(this.options.date).toFormat('dd. LLLL yyyy')
            }

            let response = await new Form({
                options,
                pid: this.pid,
                qty: this.qty,
                max: this.max
            }).post('/api/cart/add')

            this.loading = false
            this.reset()

            if (response.success) {
                App.event.fire('cart.item.inserted', response.item)
                App.event.fire('cart.updated', response.data)
            } 
        }
    }
}
</script>
