<template>
    <div class="input-group spinner" :class="{focus:focus}">
        <div v-if="icon" class="input-group-prepend">
          <div class="input-group-text">
            <i :class="icon"></i>
          </div>
        </div>
        <input name="quantity" type="number" class="form-control" @focus="focus = true" @blur="focus = false" v-model="internalValue" :readonly="readonly" id="product-quantity">
        <div class="input-group-append">
            <div class="input-group-text buttons" ref="buttons">
                <div @mousedown.prevent="up" :disabled="lazyValue >= max" class="spinner-button spinner-button-up">+</div>
                <div @mousedown.prevent="down" :disabled="lazyValue <= min" class="spinner-button spinner-button-down">-</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: 1,
    },
    min: {
      type: [Number],
      default: 1
    },
    max: {
      type: [Number],
      default: null
    },
    step: {
      type: [Number],
      default: 1
    },
    icon: {
      type: [String],
      default: null
    },
    readonly: {
      type: [Boolean],
      default: true
    }
  },

  data() {
    return {
      focus: false,
      lazyValue: this.value,
      timers: {
        timeout: null,
        interval: null
      }
    }
  },

  mounted() {
    this.lazyValue = parseFloat(this.value);
    this.$refs.buttons.addEventListener('mouseup', () => {
      this.stop()
    })
  },

  methods: {
    up() {
      this.increase()
      this.focus = true;

      this.timers.timeout = setTimeout(() => {
        this.timers.interval = setInterval(() => {
          this.increase()
        }, 50);
      }, 750);
    },
    down() {
      this.decrease();
      this.focus = true;

      this.timers.timeout = setTimeout(() => {
        this.timers.interval = setInterval(() => {
          this.decrease()
        }, 50);
      }, 750);
    },
    stop() {
      const { timeout, interval } = this.timers
      this.focus = false;

      timeout && clearTimeout(timeout)
      interval && clearInterval(interval)

      this.timers = {
        timeout: null,
        interval: null
      }
    },
    increase() {
      const { lazyValue: value, step, max } = this
      const nextStep = value + step;
      const next = max && nextStep > max ? max : nextStep;

      if (value !== next) {
        this.internalValue = next
      }
    },

    decrease() {
      const { lazyValue: value, step, min } = this
      const nextStep = value - step;
      const next = nextStep < min ? min : nextStep;

      if (value !== next) {
        this.internalValue = next
      }
    }
  },

  computed: {
    internalValue: {
      get() {
        return this.lazyValue
      },
      set(value) {
        value = typeof value === 'string' 
          ? parseFloat(value.replace(',', '.'))
          : value

        value = Math.round(value * 100) / 100

        if (value !== this.lazyValue) {
          const { min, max } = this
          this.lazyValue = (min ? value >= min : true) && (max ? value <= max : true) ? value : min;
          this.$emit('input', this.lazyValue)
          this.$emit('change', this.lazyValue)
        }
      }
    }
  },

  watch: {
    value: function (val) {
      this.lazyValue = val
    }
  }
}
</script>
