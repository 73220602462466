'use strict';

import CartModal from './components/CartModal.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('cart-modal', CartModal);

    }
}
