<template>
    <div>
        <div :hidden="hideSuccess">
            Passwort wurde erfolgreich geändert.
        </div>
        <div :hidden="hideFormFields">
            <div :class="formGroupClass('oldPassword')">
                <div class="form-group">
                    <input class="form-control" id="oldPassword" type="password" name="oldPassword" placeholder="Altes passwort" v-model="form.oldPassword" required="required"/>
                    <div class="help-block" v-if="form.errors.has('oldPassword')">
                        <strong>{{ form.errors.get('oldPassword') }}</strong>
                    </div>
                </div>
            </div>
            <div :class="formGroupClass('newPassword')">
                <div class="form-group">
                    <input class="form-control" id="newPassword" type="password" name="newPassword" placeholder="Neues passwort" v-model="form.newPassword" required="required"/>
                    <div class="help-block" v-if="form.errors.has('newPassword')">
                        <strong>{{ form.errors.get('newPassword') }}</strong>
                    </div>
                </div>
            </div>
            <div :class="formGroupClass('newPassword_confirmation')">
                <div class="form-group">
                    <input class="form-control" id="newPassword_confirmation" type="password" name="newPassword_confirmation" placeholder="Wiederholung neues passwort" v-model="form.newPassword_confirmation" required="required"/>
                    <div class="help-block" v-if="form.errors.has('newPassword_confirmation')">
                        <strong>{{ form.errors.get('newPassword_confirmation') }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <button class="btn active loginBTN" type="button" @click="onClick" :disabled="loading">
                    <i class="fa fa-spinner fa-spin" v-if="loading"></i>
                    <slot>SPEICHERN</slot>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    data() {
        return {
            form: new Form({
                oldPassword: null,
                newPassword: null,
                newPassword_confirmation: null
            }),
            loading: false,
            hideFormFields: false,
            hideSuccess: true
        }
    },

    methods: {
        formGroupClass(name) {
            return this.form.errors.has(name) ? 'form-group has-error' : 'form-group';
        },

        onClick() {
            this.loading = true;
            App.event.fire('newPassword.send');
        }
    },

    created() {

        App.event.listen('newPassword.send', () => {
            this.form.post('/profile/user-data/password-change').then(response => {
                this.hideFormFields = true;
                this.hideSuccess = false;
            }).catch(errors => {
                this.loading = false;
                App.event.fire('newPassword.failed', errors);
            });
        });
    }
}
</script>
