<template>
  <div :data-role="type">
    <div class="product-list">
      <div class="row" ref="container">
        <div class="col-md-3" v-for="item in items" :key="item.id">
          <slider-item v-bind="item"></slider-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderItem from './SliderItem'
import { tns } from 'tiny-slider/src/tiny-slider'

const config = {
  autoplayButtonOutput: false,
  mouseDrag: true,
  controls: true,
  autoplay: false,
  nav: false,
  items: 1,
  responsive: {
    768: {
      items: 2
    },
    992: {
      items: 3
    },
    1200: {
      items: 4
    },
  }
}

export default {
  components: {
    SliderItem
  },
  props: {
    type: String,
    items: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      config
    }
  },
  mounted () {
    tns({
      ...config,
      container: this.$refs.container
    })
    this.$nextTick(() => this.fixHeights())
  },
  methods: {
    fixHeights () {
      let maxHeight = 0;
      const slides = this.$refs.container.querySelectorAll('.product-list--item-content');
      [...slides].forEach(slide => {
        maxHeight = Math.max(maxHeight, slide.clientHeight)
      });
      [...slides].forEach(slide => {
        slide.style.height = `${maxHeight}px`
      });
    }
  }
}
</script>

<style lang="scss">
$blue: #3B5998;
$gray: #58585A;
$font-quicksand: "Quicksand";

@import '../../../../sass/elements/product-list.scss';
</style>