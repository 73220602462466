'use strict';

import Event from './event';
import * as Module from '../modules/';
import IsScrolling from '@Utils/is-scrolling';
import { tns } from 'tiny-slider/src/tiny-slider';

require('fslightbox');

export default class {
    constructor() {
        let token = document.head.querySelector('meta[name="csrf-token"]');

        this.reactive = Vue.observable({
            token: token ? token.content : null,
        });

        this.event = new Event();
        this.countries = [];
        this.defaults = {};

        new Module.AddToCart();
        new Module.AddToCartButton();
        new Module.Register();
        new Module.CartModal();
        new Module.CartSidebar();
        new Module.ProductPrice();
        new Module.CartNumItems();
        new Module.CartHeader();
        new Module.Checkout();
        new Module.Wirecard();
    }

    updateCartQuantity() {
        this.event.listen('cart.data.loaded', ({ data }) => {
            var imgs = [].slice
                .call(
                    document.querySelectorAll('img[src$="shopping-cart.svg"]')
                )
                .forEach((img) => {
                    var navItem = img.parentElement;

                    while (navItem.className != 'nav-item') {
                        navItem = navItem.parentElement;
                    }

                    var old = navItem.querySelector('.item-count');
                    if (old) {
                        navItem.removeChild(old);
                    }

                    if (data.count == 0) {
                        return;
                    }

                    var count = document.createElement('div');
                    count.className = 'item-count';
                    count.style.display = 'block';
                    count.style.position = 'absolute';
                    count.style.color = 'white';
                    count.style.background = '#3B5998';
                    count.style.lineHeight = '22px';
                    count.style.height = '22px';
                    count.style.width = '22px';
                    count.style.textAlign = 'center';
                    count.style.borderRadius = '50%';
                    count.style.top = '4px';
                    count.style.right = '-7px';
                    count.style.fontFamily = 'sans-serif';
                    count.style.fontSize = '12px';
                    count.style.fontWeight = 'bold';
                    count.innerText = data.count;

                    navItem.appendChild(count);
                });
        });

        this.event.listen('cart.updated', ({ data }) => {
            var imgs = [].slice
                .call(
                    document.querySelectorAll('img[src$="shopping-cart.svg"]')
                )
                .forEach((img) => {
                    var navItem = img.parentElement;

                    while (navItem.className != 'nav-item') {
                        navItem = navItem.parentElement;
                    }

                    var old = navItem.querySelector('.item-count');
                    if (old) {
                        navItem.removeChild(old);
                    }

                    if (data.count == 0) {
                        return;
                    }

                    var count = document.createElement('div');
                    count.className = 'item-count';
                    count.style.display = 'block';
                    count.style.position = 'absolute';
                    count.style.color = 'white';
                    count.style.background = '#3B5998';
                    count.style.lineHeight = '22px';
                    count.style.height = '22px';
                    count.style.width = '22px';
                    count.style.textAlign = 'center';
                    count.style.borderRadius = '50%';
                    count.style.top = '4px';
                    count.style.right = '-7px';
                    count.style.fontFamily = 'sans-serif';
                    count.style.fontSize = '12px';
                    count.style.fontWeight = 'bold';
                    count.innerText = data.count;

                    navItem.appendChild(count);
                });
        });
    }

    init(config) {
        this.config = { ...this.defaults, ...config };
        this.url = config.url
            ? config.url.replace(/\/+$/, '')
            : location.origin;

        this.vue = new Vue({
            el: '#app',
        });

        this.inview = new Module.InView();
        this.menu = new Module.OffCanvas();
        this.isScrolling = new IsScrolling('#app > .content');

        if (this.config.map) {
            this.inview.observe(
                this.config.map.el || '[data-role="google-map"]',
                async (el) => {
                    this.map = new Module.Map(this.config.map);
                }
            );
        }

        // - Trigger cookie consent banner.
        // cookieconsent.initialise(Config.cookieconsent);

        this.initSliders();
        this.initDropdowns();
        this.initImageInteractions();
        this.initCategoryCheckboxLinks();

        this.updateCartQuantity();

        this.cartData();
        this.fetchCountries();
        refreshFsLightbox();
    }

    cartData() {
        new Form().get('/api/cart/content').then((response) => {
            this.event.fire('cart.data.loaded', response);
        });
    }

    fetchCountries() {
        new Form().get('/register/countries').then((countries) => {
            this.countries = countries;
            this.event.fire('countries.loaded', countries);
        });
    }

    initSliders() {
        if (document.querySelector('.fs-slider')) {
            for (const el of document.querySelectorAll('.fs-slider')) {
                tns({
                    container: el,
                    autoplayButtonOutput: false,
                    arrowKeys: true,
                    mouseDrag: true,
                    controls: false,
                    autoplay: true,
                    mode: 'gallery',
                    speed: 750,
                });
            }
        }

        if (document.querySelector('.fs-slider-product')) {
            for (const el of document.querySelectorAll('.fs-slider-product')) {
                tns({
                    container: el,
                    autoplayButtonOutput: false,
                    arrowKeys: true,
                    mouseDrag: true,
                    controls: false,
                    autoplay: true,
                    mode: 'gallery',
                    nav: false,
                    speed: 750,
                });
            }
        }

        if (
            document.querySelectorAll('.product-list.slider > .row').length > 0
        ) {
            [].forEach.call(
                document.querySelectorAll('.product-list.slider > .row'),
                (productList) => {
                    tns({
                        container: productList,
                        autoplayButtonOutput: false,
                        mouseDrag: true,
                        controls: true,
                        autoplay: false,
                        nav: false,
                        items: 1,
                        responsive: {
                            768: {
                                items: 2,
                            },
                            992: {
                                items: 3,
                            },
                            1200: {
                                items: 4,
                            },
                        },
                    });
                }
            );
        }
    }

    initDropdowns() {
        [].forEach.call(
            document.querySelectorAll('[data-toggle="dropdown"]'),
            (ddToggle) => {
                ddToggle.addEventListener(
                    'click',
                    ({ currentTarget: button }) => {
                        const dropdown = button.nextElementSibling;
                        dropdown && dropdown.classList.contains('show')
                            ? dropdown.classList.remove('show')
                            : dropdown.classList.add('show');
                    }
                );
            }
        );

        [].forEach.call(
            document.querySelectorAll('[data-close="dropdown"]'),
            (closeBtn) => {
                closeBtn.addEventListener(
                    'click',
                    ({ currentTarget: button }) => {
                        button.parentNode.classList.remove('show');
                    }
                );
            }
        );
    }

    initImageInteractions() {
        if (document.querySelectorAll('img[data-hover]')) {
            [].forEach.call(
                document.querySelectorAll('img[data-hover]'),
                (img) => {
                    img.addEventListener(
                        'mouseover',
                        ({ currentTarget: image }) => {
                            let src = image.dataset.src || image.src;
                            let ext = src.split('.').pop();
                            image.dataset.src = src;
                            image.src = src.replace(`.${ext}`, `-hover.${ext}`);
                        }
                    );
                    img.addEventListener(
                        'mouseout',
                        ({ currentTarget: image }) => {
                            image.src = image.dataset.src;
                        }
                    );
                }
            );
        }
    }

    initCategoryCheckboxLinks() {
        if (document.querySelectorAll('.filters--list li > label')) {
            [].forEach.call(
                document.querySelectorAll('.filters--list li > label'),
                (item) => {
                    item.addEventListener(
                        'click',
                        ({ currentTarget: label }) => {
                            let anchor = label.querySelector('a');
                            if (anchor) {
                                window.location = anchor.href;
                            }
                        }
                    );
                }
            );
        }
    }
}
