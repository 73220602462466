'use strict';

import ShippingType from './components/ShippingType.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('shipping-type', ShippingType);

    }
}
