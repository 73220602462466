<template>
    <div class="cartItem">
        <div class="remove" v-if="!readonly">
            <a href="#" @click.prevent="remove">
                <i :class="removeIcon"></i>
            </a></div>
        <div class="productTitle">{{ item.name }}</div>
        <div class="productQuantity">{{ item.qty }}</div>
        <div class="price">{{ subtotal }} €</div>
    </div>
</template>

<script>
export default {

    props: {
        item: {
            required: true
        },
        readonly: {
            default: false
        }
    },

    data() {
        return {
            loading: false,
            removing: false,
            initialized: false,
        }
    },

    methods: {

        updateQty(qty) {

            // Prevent update on first load.
            if (this.initialized) {
                this.loading = true;
                new Form({rowId: this.item.rowId, qty:qty})
                .post('/api/cart/update')
                .then((response) => {
                    App.event.fire('cart.updated', response);
                    this.loading = false;
                });
            }
            this.initialized = true;
        },

        remove() {
            this.removing = true;
            new Form({rowId: this.item.rowId})
            .post('/api/cart/remove')
            .then((response) => {
                App.event.fire('cart.updated', response);
                this.removing = false;
            });
        }

    },

    computed: {

        subtotal() {
            return parseFloat(this.item.subtotal*1.19).toFixed(2).replace('.', ',');
        },

        removeIcon() {
            return this.removing ? 'fa fa-spinner fa-spin' : 'fa fa-times';
        }

    }


}
</script>

