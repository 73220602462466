import Vue from 'vue';
import Slider from '../modules/slider'
import Form from './form';

export default class Sliders {
    constructor () {
        this.url = window.SLIDER_API || location.origin + '/shop/sliders';
        this.container = [];
        this.init()
    }

    async init () {
        let sliders = await new Form().get(this.url);
        if (sliders) {
            for (const type in sliders) {
                if (sliders.hasOwnProperty(type)) {
                    const el = document.querySelector(`[data-role="${type}"]`)
                    if (el) {
                        const items = sliders[type];
                        const vm = new Vue({
                        render: h => h(Slider, {
                            props: { items, type }
                        })
                        }).$mount(el)

                        this.container.push({ el, vm, items })
                    }
                }
            }
        }
    }
}