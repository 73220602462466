<template>
    <button @click="submit" :disabled="loading" class="btn">
        <i v-if="loading" class="fa fa-spin fa-spinner"></i>
        <slot>in den Warenkorb</slot>
    </button>
</template>
<script>
export default {
  props: {
      pid: {
        type: [String, Number],
        required: true,
        default: null
      }
  },

  data() {
      return {
          qty: 1,
          loading: false
      }
  },

  methods: {
      submit() {

        this.loading = true;

        new Form({
            pid: this.pid,
            qty: 1
        })
        .post('/api/cart/add')
        .then((response) => {
            this.loading = false;
            if(response.success) {
                App.event.fire('cart.item.inserted', response.item);
                App.event.fire('cart.updated', response.data);
            } else {
                swal("", "Sie wollen mehr Produkte in den Warenkorb hinzufügen als derzeit lagernd sind.",  "error");
            }
            
        }).catch(errors => {
            this.loading = false;
        });
      }

  }
}
</script>
