<template>
    <div class="row">
        <div class="col-sm-12">
            <button @click.prevent="submit" class="btn large btn-order">Jetzt zahlungspflichtig bestellen</button>
        </div>
    </div>        
</template>

<script>
export default {

    methods: {
        submit() {
            this.$emit('submit');
        }
    }

}
</script>
