<template>
<div class="col-12">
    <section id='register-user'>
        <div class="row">
            <div class="col-sm-12 blackBar">
                <h2 class="h3">Kontaktdaten</h2>
            </div>
        </div>
        <div class="backgroundGray">
            <div class="row">
                <div class="col-sm-6">
                    <div :class="formGroupClass('firm')">
                        <input class="form-control" :class="{'is-invalid': form.errors.has('firm')}" id="firm" type="text" name="firm" placeholder="Firma" v-model="form.firm"/>
                        <div class="invalid-feedback" v-if="form.errors.has('firm')">
                            <strong>{{ form.errors.get('firm') }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div :class="formGroupClass('vatin')">
                        <input class="form-control" :class="{'is-invalid': form.errors.has('vatin')}" id="vatin" type="text" name="vatin" placeholder="UID" v-model="form.vatin"/>
                        <div class="invalid-feedback" v-if="form.errors.has('vatin')">
                            <strong>{{ form.errors.get('vatin') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div :class="formGroupClass('salutation')">
                        <select class="form-control" :class="{'is-invalid': form.errors.has('salutation')}" v-model="form.salutation">
                            <option value="" disabled selected>Anrede</option>
                            <option value="Herr">Herr</option>
                            <option value="Frau">Frau</option>
                        </select>
                        <div class="invalid-feedback" v-if="form.errors.has('salutation')">
                            <strong>{{ form.errors.get('salutation') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div :class="formGroupClass('firstname')">
                        <input class="form-control" :class="{'is-invalid': form.errors.has('firstname')}" id="firstname" type="text" name="firstname" placeholder="Vorname" v-model="form.firstname" required="required" autofocus="autofocus"/>
                        <div class="invalid-feedback" v-if="form.errors.has('firstname')">
                            <strong>{{ form.errors.get('firstname') }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div :class="formGroupClass('lastname')">
                        <input class="form-control" :class="{'is-invalid': form.errors.has('lastname')}" id="lastname" type="text" name="lastname" placeholder="Nachname" v-model="form.lastname" required="required"/>
                        <div class="invalid-feedback" v-if="form.errors.has('lastname')">
                            <strong>{{ form.errors.get('lastname') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div :class="formGroupClass('street')">
                        <input class="form-control" :class="{'is-invalid': form.errors.has('street')}" id="street" type="text" name="street" placeholder="Straße und Hausnr." v-model="form.street" required="required"/>
                        <div class="invalid-feedback" v-if="form.errors.has('street')">
                            <strong>{{ form.errors.get('street') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8">
                    <div :class="formGroupClass('city')">
                        <input class="form-control" :class="{'is-invalid': form.errors.has('city')}" id="city" type="text" name="city" placeholder="Ort" v-model="form.city" required="required"/>
                        <div class="invalid-feedback" v-if="form.errors.has('city')">
                            <strong>{{ form.errors.get('city') }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div :class="formGroupClass('postalCode')">
                        <input class="form-control" :class="{'is-invalid': form.errors.has('postalCode')}" id="postalCode" type="text" name="postalCode" placeholder="PLZ" v-model="form.postalCode" required="required"/>
                        <div class="invalid-feedback" v-if="form.errors.has('postalCode')">
                            <strong>{{ form.errors.get('postalCode') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div :class="formGroupClass('country')">
                        <select class="form-control" :class="{'is-invalid': form.errors.has('country')}" id="country" name="country" v-model="form.country" required="required">
                                <option value="" disabled selected>Land</option>
                                <option v-for="country in countries" :key="country.id" :value="country.id" :selected="(country.id == form.country)">{{ country.name }}</option>
                        </select>
                        <div class="invalid-feedback" v-if="form.errors.has('country')">
                            <strong>{{ form.errors.get('country') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 blackBar"><div class="title">Telefon</div></div>
        </div>
        <div class="backgroundGray">
            <div class="row">
                <div class="col-sm-12">
                    <div :class="formGroupClass('phone')">
                        <input class="form-control" :class="{'is-invalid': form.errors.has('phone')}" id="phone" type="text" name="phone" placeholder="Telefon" v-model="form.phone" required="required"/>
                        <div class="invalid-feedback" v-if="form.errors.has('phone')">
                            <strong>{{ form.errors.get('phone') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 blackBar"><div class="title">Email</div></div>
        </div>
        <div class="backgroundGray">
            <div class="row">
                <div class="col-sm-8">
                    <div :class="formGroupClass('email')">
                        <input class="form-control" :class="{'is-invalid': form.errors.has('email')}" id="email" type="email" name="email" placeholder="E-Mail" v-model="form.email" required="required"/>
                        <div class="invalid-feedback" v-if="form.errors.has('email')">
                            <strong>{{ form.errors.get('email') }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div :class="formGroupClass('email_confirmation')">
                        <input class="form-control" :class="{'is-invalid': form.errors.has('email_confirmation')}" id="email_confirmation" type="email" name="email_confirmation" placeholder="E-Mail wiederholen" v-model="form.email_confirmation" required="required"/>
                        <div class="invalid-feedback" v-if="form.errors.has('email_confirmation')">
                            <strong>{{ form.errors.get('email_confirmation') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 blackBar"><div class="title">Passwort</div></div>
        </div>
        <div class="backgroundGray">
            <div class="row">
                <div class="col-sm-8">
                    <div :class="formGroupClass('password')">
                        <input class="form-control" :class="{'is-invalid': form.errors.has('password')}" id="pwd" type="password" name="password" v-model="form.password" placeholder="Passwort" required="required"/>
                        <div class="invalid-feedback" v-if="form.errors.has('password')">
                            <strong>{{ form.errors.get('password') }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <input class="form-control" id="password-confirm" type="password" name="password_confirmation" v-model="form.password_confirmation" placeholder="Passwort wiederholen" required="required"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="form.errors.has('global')">
            <div class="col-sm-12">
                <div class="alert alert-danger">
                    <i class="fa fa-exclamation-triangle"></i> 
                    {{ form.errors.get('global') }}
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>

export default {

    data() {
        return {
            form: new Form({
                salutation: '',
                firm: null,
                vatin: null,
                firstname: null,
                lastname: null,
                street: null,
                postalCode: null,
                city: null,
                country: '',
                phone: null,
                email: null,
                mailNl: null,
                password: null,
                email_confirmation: null,
                password_confirmation: null
            }),
            countries: []
        }
    },

    methods: {
        formGroupClass(name) {
            return this.form.errors.has(name) ? 'form-group has-error' : 'form-group';
        }
    },

    created() {

        this.countries = App.countries;
        App.event.listen('countries.loaded', countries => this.countries = countries);

        //- register user
        App.event.listen('registration.send', () => {
            this.form.put('/register').then(response => {
                App.event.fire('registration.success', response);
            }).catch(errors => {
                App.event.fire('registration.failed', errors);
            });
        });

    }
}
</script>
