<template>
    <span>
        <slot></slot>
        {{ internalPrice | currency }}
        <slot name="after"></slot>
    </span>
</template>
<script>
export default {
    props: {
        price: {
            type: [Number],
            required: true
        }
    },
    data () {
        return {
            internalPrice: this.price
        }
    },
    created () {
        App.event.listen('product.price.changed', (price) => {
            this.internalPrice = price
        })
    },
    filters: {
        currency (value) {
            return parseFloat(value)
                .toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR'
                })
        }
    }
}
</script>
