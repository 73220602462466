<template>
    <section id="checkout-user-handler">
        <br><br>
        <div class="row">
            <div class="col-sm-6 text-center">
                <button class="btn btn-blue" type="button" @click="setMode('login')">Mit Kundenkonto kaufen</button>
            </div>
            <div class="col-sm-6 text-center">
                <button class="btn btn-blue" type="button" @click="setMode('guest')">Als Gast kaufen</button>                    
            </div>
        </div>

        <div class="row" v-if="mode == 'login'">
            <div class="col-sm-12 mt-4 mb-4">
                <login></login>    
                <br>        
                <button class="btn btn-blue btn-login" type="button" @click="login">Anmelden</button>
            </div>
            <div class="col-sm-12">
                <button class="btn btn-dark btn-register" type="button" @click="setMode('register')">Registrieren</button>
            </div>
        </div>

        <div class="row mt-4 mb-4" v-if="mode == 'register'">
            <register></register>
            <div class="col-12">
                <br>
                <button class="btn btn-primary btn-register" type="button" @click="register">Registrieren</button>
            </div>
        </div>

        <div class="row mt-4 mb-4" v-if="mode == 'guest'">
            <div class="col-12">
                <guest></guest>
                <br>
                <button class="btn btn-primary btn-continue" type="button" @click="guest">Weiter</button>
            </div>
        </div>

    </section>
</template>

<script>
import Login from '../../../../login/components/Login';

export default {
    components: {
        Login
    },

    data() {
        return {
            mode: null
        }
    },

    methods: {

        setMode(mode) {
            this.mode = mode;
        },

        login() {
            App.event.fire('login.send');
        },

        register() {
            App.event.fire('registration.send');
        },

        guest() {
            App.event.fire('guest.send');
        }
    }
}
</script>