import Errors from './errors';
import to from '@Utils/to';

export default class Form {

    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor(data) {
        this.originalData = data;

        for (let field in data) {
            this[field] = data[field];
        }

        this.errors = new Errors();
    }

    /**
     * Fetch all relevant data for the form.
     */
    data() {
        let data = {};

        for (let property in this.originalData) {
            data[property] = this[property];
        }

        return data;
    }


    /**
     * Reset the form fields.
     */
    reset() {
        for (let field in this.originalData) {
            this[field] = '';
        }

        this.errors.clear();
    }


    /**
     * Send a GET request to the given URL.
     * .
     * @param {string} url
     */
    get(url) {
        return this.submit('get', url);
    }

    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     */
    post(url) {
        return this.submit('post', url);
    }


    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     */
    put(url) {
        return this.submit('put', url);
    }


    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     */
    patch(url) {
        return this.submit('patch', url);
    }


    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     */
    delete(url) {
        return this.submit('delete', url);
    }


    /**
     * Submit the form.
     *
     * @param {string} requestMethod
     * @param {string} urlPath
     */
    async submit(method, path) {
        const token = document.head.querySelector('meta[name="csrf-token"]');
        if (token) {
            this.updateToken(token.content)
        }

        const url = typeof App === 'undefined' ? path : (App.url || '') + path
        const request = window.axios[method]
        const [error, response] = await to(request(url, this.data()))
        
        if (error) {
            if ([401, 422].filter(code => error.request.status === code).length) {
                this.onFail(error.response.data);
                return Promise.reject(error.response.data)
            } else {
                console.error(error);
                return Promise.reject(error)
            }
        }

        const { data } = response

        if (data._token) {
            this.updateToken(data._token)
        }

        this.onSuccess(data)
        return Promise.resolve(data)
    }

    /**
     * Updates CSRF token globaly in App, meta and 
     * all hidden input fields.
     * 
     * @param {string} token CSRF Token String
     */
    updateToken(token) {
        App.reactive.token = token

        window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

        const meta = document.head.querySelector('meta[name="csrf-token"]');
        if (meta) {
            meta.content = token
        }

        [].forEach.call(
            document.querySelectorAll('input[name="_token"]'),
            node => node.value = token
        )
    }


    /**
     * Handle a successful form submission.
     *
     * @param {object} data
     */
    onSuccess(data) {
        this.reset();
    }


    /**
     * Handle a failed form submission.
     *
     * @param {object} errors
     */
    onFail({errors}) {
        this.errors.record(errors);
    }
}