<template>
    <div id="cart-header">
        <div class="title" v-text="title"></div>
        <hr>
        <cart-header-row v-for="item in items" :key="item.rowId" :item="item" :readonly="readonly"></cart-header-row>
        <hr>
        <div class="cartItem">
            <div class="remove"></div>
            <div class="sumText">Summe</div>
            <div class="sumPrice">{{ total }} €</div>
        </div>
        <a class="btn btn-dark toCartBTN" :href="url">Zum warenkorb</a>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            default: 'warenkorb'
        },
        readonly: {
            default: false
        },
        comment: {
            default: ''
        }
    },
    data() {
        return {
            items: {},
            data: {
                total: '0'
            }
        }
    },
    created() {
        App.event.listen('cart.data.loaded', (response) => {
            this.items = response.items;
            this.data  = response.data;
        });

        App.event.listen('cart.updated', (response) => {
            this.items = response.items,
            this.data = response.data
        });
    },

    computed: {
        url() {
            return App.url + '/cart';
        },

        total() {
            return parseFloat(this.data.total).toFixed(2).replace('.', ',');
        }
    }

}
</script>
