<template>
    <div class="credit-card-form">
        <div class="row">
            <div class="col-sm-12 blackBar">
                <div class="title">Kreditkarte <i v-if="!dataStorage" class="fa fa-spin fa-spinner"></i></div>
            </div>
        </div>
        <div class="row backgroundGray">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="paymentInformation.cardholdername" name="cardHolderName" placeholder="Karteninhaber" maxlength="30" id="cardHolderName">                        
                    </div>                    
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <input type="text" class="form-control" v-mask="'#### #### #### ####'" v-model="paymentInformation.pan" name="pan" placeholder="Kreditkartennummer 0000-0000-0000-0000" maxlength="19" id="pan">
                    </div>                    
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-xs-12">
                    <div class="form-group">
                        <input type="text" class="form-control" v-mask="'##'" v-model="paymentInformation.expirationMonth" name="expirationMonth" placeholder="Ablaufmonat 00" maxlength="2" id="expirationMonth">
                    </div>                    
                </div>
                <div class="col-md-4 col-xs-12">
                    <div class="form-group">
                        <input type="text" class="form-control" v-mask="'####'" v-model="paymentInformation.expirationYear" name="expirationYear" placeholder="Ablaufjahr 0000" maxlength="4" id="expirationYear">
                    </div>                    
                </div>
                <div class="col-md-4 col-xs-12">
                    <div class="form-group">
                        <input type="text" class="form-control" v-mask="'####'" v-model="paymentInformation.cardverifycode" name="cardVerifyCode" placeholder="Kartenprüfnummer"  maxlength="4" id="cardVerifyCode">
                    </div>                    
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <button @click.prevent="submit" class="btn large btn-order">Jetzt zahlungspflichtig bestellen</button>
            </div>
        </div>        
    </div>
</template>

<script>
const objectAssign = require('object-assign');

export default {

    props: {
        javascriptUrl: {
            type: [String],
            required: true
        }
    },

    data() {

        return {
            dataStorage: null,
            paymentInformation: {
                pan: '',
                expirationMonth: null,
                expirationYear: null,
                cardholdername: null,
                cardverifycode: null
            }
        }

    },

    mounted() {

        this.loadScript(this.javascriptUrl).then(() => {
            this.dataStorage = new WirecardCEE_DataStorage();
        });

    },

    methods: {

        loadScript(url) {
            return new Promise((resolve, reject) => {
                var script = document.createElement('script');

                document.body.appendChild(script);

                script.onload = () => resolve();
                script.src = url;
            });
        },

        dataStorageHandler(response) {

            if (response.getStatus() == 0) {
                this.$emit('submit');
            } else {
                var errors = response.getErrors();
                var error = errors.shift();

                swal({
                    html:  true,
                    type:  'error',
                    title: 'Fehler',
                    text: error.consumerMessage,
                });
            }

        },

        submit() {

            if (this.dataStorage) {

                let paymentInformation = objectAssign({}, this.paymentInformation);
                    paymentInformation.pan = paymentInformation.pan.replace(/ /g, '');

                this.dataStorage.storeCreditCardInformation(
                    paymentInformation,
                    this.dataStorageHandler
                );

            }
        },

    }
}
</script>
