<template>
<section class="basket">
  <div class="basket--header">
    <div class="basket--title">
      Warenkorb
    </div>
    <div class="basket--count" :class="{'text-blue': count > 0}">
      {{ count }}
    </div>
  </div>
  <ul v-if="show" class="basket--list">
    <li v-for="item in items" :key="item.rowId">
      <div class="qty">{{ item.qty }}</div>
      <div class="description" :title="item.name" v-html="item.name"></div>
      <div class="price">€ {{ item.total | money }}</div>
    </li>
    <li>
      <div class="total">Gesamt</div>
      <div class="price">€ {{ data.total | money }}</div>
    </li>
  </ul>
  <a :href="url" v-if="show" class="btn btn-blue w-100">Zur Kassa</a>
</section>
</template>

<script>
export default {

  data() {
    return {
        items: {},
        data: {
            total: '0'
        }
    }
  },

  created() {
        App.event.listen('cart.data.loaded', (response) => {
            this.items = response.items;
            this.data  = response.data;
        });

        App.event.listen('cart.updated', (response) => {
            this.items = response.items,
            this.data = response.data
        });
  },

  methods: {
      close() {
          this.product = null;
      }
  },

  computed: {
      url() {
          return App.url + '/cart';
      },

      count() {
          return Object.keys(this.items)
            .map((key) => this.items[key].qty)
            .reduce((prev, curr) => prev + curr, 0);
      },

      show() {
          return Object.keys(this.items).length !== 0
      },
  },

  filters: {
    money(val) {
      return parseFloat(val).toFixed(2).replace('.', ',');      
    }
  }

}
</script>
