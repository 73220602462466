'use strict';

import Register from "./components/Register.vue";
import RegisterButton from "./components/RegisterButton.vue";

export default class {

    constructor() {
        Vue.component('register', Register);
        Vue.component('register-button', RegisterButton);
    }
}
