<template>
    <div class="shipping-address">
        <div class="row">
            <div class="col-sm-8">
                <b class="title">Lieferadresse</b>
            </div>
            <div class="col-sm-4">
                <!-- <a class="btn btn-blue btn-sm" href="#" @click.prevent="loadAddressBook">
                    <i class="fa fa-spin fa-spinner" v-if="loadingAddressBook"></i>
                    Adressbuch öffnen
                </a> -->
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-12">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" name="useUserAddress" v-model="useUserAddress">
                        <span>
                            Rechnungsadresse ist Lieferadresse
                            <i v-if="useUserAddress && user">
                                ( {{address.company}} {{user.salutation}} {{user.firstName}} {{user.lastName}}, {{user.street}}, {{user.city}} {{user.zip}} )
                            </i>
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div v-if="addressBook && addressBook.length">
            <div v-for="(address, index) in addressBook" class="row" :key="index" style="padding:5px 0; border-top:1px solid white;">
                <div class="col-sm-9" style="line-height:40px; padding-left:15px;">
                    {{index + 1}}. {{address.company}} {{address.salutation}} {{address.firstName}} {{address.lastName}}, {{address.street}}, {{address.city}} {{address.zip}}
                </div>
                <div class="col-sm-3 blackBar" style="margin:0;">
                    <a class="btn btn-sm btn-blue" href="#" @click.prevent="selectAddreess(index)">
                        Auswählen
                    </a>
                </div>
            </div>
        </div>
        <div v-if="!useUserAddress">
            <div class="row">
                <input type="hidden" name="shippingAddressId" v-if="selectedAddress" :value="selectedAddress" />
                <div class="col-sm-6">
                    <div class="form-group">
                        <input class="form-control" name="address[company]" @change="onFieldChange" v-model="address.company" placeholder="Firma">
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <select class="form-control" name="address[salutation]" @change="onFieldChange" v-model="address.salutation">
                            <option value="" disabled selected>Anrede</option>
                            <option value="Herr">Herr</option>
                            <option value="Frau">Frau</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <input class="form-control" name="address[firstname]" @change="onFieldChange" v-model="address.firstName" placeholder="Vorname">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <input class="form-control" name="address[lastname]" @change="onFieldChange" v-model="address.lastName" placeholder="Nachname">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <input class="form-control" name="address[street]" @change="onFieldChange" v-model="address.street" placeholder="Straße + Hausnr.">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8">
                    <div class="form-group">
                        <input class="form-control" name="address[city]" @change="onFieldChange" v-model="address.city" placeholder="Ort">
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <input class="form-control" name="address[zip]" @change="onFieldChange" v-model="address.zip" placeholder="PLZ">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8">
                    <div class="form-group">
                        <input class="form-control" name="address[mail]" @change="onFieldChange" v-model="address.mail" placeholder="E-Mail">
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <input class="form-control" name="address[phone]" @change="onFieldChange" v-model="address.phone" placeholder="Telefon">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <select class="form-control" name="address[country]" @change="onFieldChange" v-model="address.country">
                            <option value="" disabled selected>Land</option>
                            <option v-for="country in countries" :key="country.id" :value="country.id" :selected="(country.id == address.country)">{{ country.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        user: {
            required: true,
            default: null
        }
    },

    data() {
        return {
            useUserAddress: true,
            loadingAddressBook: false,
            address: {...this.user},
            addressBook: null,
            selectedAddress: null,
            countries: []
        }
    },

    methods: {
        
        loadAddressBook() {
            this.loadingAddressBook = true;
            new Form().get('/api/me/addressbook')
            .then(addressBook => {
                this.loadingAddressBook = false;
                if (addressBook.length) {
                    this.addressBook = addressBook;
                } else {

                }
            }).catch(error => {
                console.log(error)
            });
        },

        selectAddreess(index) {
            this.useUserAddress = false;
            this.address = this.addressBook[index];
            this.selectedAddress = this.address.id;
            this.addressBook = null;
            this.$emit('change', {...this.address});
        },

        onFieldChange() {
            this.selectedAddress = null;
            this.$emit('change', {...this.address});
        }
    },

    created() {
        this.countries = App.countries;
        setTimeout(() => {
            this.$emit('change', {...this.address});                    
        }, 500);
        App.event.listen('countries.loaded', countries => this.countries = countries);
    },

    watch: {
        user: function (user) {
            this.address = {...user}
        },
        useUserAddress: function (val) {
            if (val) {
                this.address = {...this.user}                
            } else {
                for (const key in this.address) {
                    this.address[key] = '';
                }
            }

        }
    }

}
</script>
