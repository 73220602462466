/**
 * Helper to determine if page is scrolled
 * and directions of scrolling.
 *
 * @author Matej Svajger <hello@matejsvajger.com>
 */

export default class IsScrolling {

  constructor(target) {
    this.lastScrollPos = 0;
    this.target = typeof target === 'string'
      ? document.querySelector(target)
      : target
    document.addEventListener("DOMContentLoaded", () => this.init());
  }

  init() {
    this.lastScrollPos = this.target.scrollTop;

    this.apply();
    this.target.addEventListener('scroll', this);
  }

  apply() {
    this.target && this.target.scrollTop > 0
      ? document.body.classList.add('is-scrolled')
      : document.body.classList.remove('is-scrolled')

    if (this.target && this.target.scrollTop === 0) {
      document.body.classList.remove('is-scrolling-up')
      document.body.classList.remove('is-scrolling-down')
    } else {
      this.target && this.target.scrollTop > this.lastScrollPos
        ? (
          document.body.classList.add('is-scrolling-down') ||
          document.body.classList.remove('is-scrolling-up')
        ) : (
          document.body.classList.add('is-scrolling-up') ||
          document.body.classList.remove('is-scrolling-down')
        )
    }

    this.lastScrollPos = this.target.scrollTop <= 0 ? 0 : this.target.scrollTop;
  }

  handleEvent() {
    this.apply();
  }

  destroy() {
    this.target.removeEventListener('scroll', this);
    document.body.classList.remove('is-scrolled')
    document.body.classList.remove('is-scrolling-up')
    document.body.classList.remove('is-scrolling-down')
  }
}
