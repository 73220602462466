<template>
    <div id="cart-modal" v-if="product">
        <div class="modal fade" :class="show" tabindex="-1" role="dialog" style="display: block">
            <div class="modal-dialog" role="document" style="max-width: 700px;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Zum Warenkorb hinzugefügt</h4>
                        <button type="button" class="close" @click="close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Produkt</th>
                                        <th>Preis</th>
                                        <th>Anzahl</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td v-html="name"></td>
                                        <td>{{ product.price }} €</td>
                                        <td>{{ product.qty }}</td>
                                        <td>{{ product.subtotal }} €</td>
                                    </tr>
                                </tbody>
                            </table>
                            <!--img class="img-responsive img-thumbnail" :src="product.image">-->
                        </div>
                    </div>
                    <div class="modal-footer">
                        <a :href="url" class="btn btn-blue">Zum Warenkorb!</a>
                        <button type="button" class="btn btn-default" @click="close">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade" :class="show"></div>
    </div>
</template>

<script>
export default {

    data: () => ({
        product: null
    }),

    created() {
        App.event.listen('cart.item.inserted', (product) => {
            product.subtotal = product.subtotal + (product.tax * product.qty);
            product.subtotal = product.subtotal.toFixed(2).replace(".", ",");
            product.price = product.price + product.tax;
            product.price = product.price.toFixed(2).replace(".", ",");
            product.tax = parseFloat(product.tax).toFixed(2).replace(".", ",");
            this.product = product;
        });
    },

    methods: {
        close() {
            this.product = null;
        }
    },

    computed: {
        url() {
            return App.url + '/cart';
        },

        show() {
            return this.product ? 'show' : '';
        },

        name() {
            return `
                ${this.product.name}
                <br>
                ${this.product.options ? Object.keys(this.product.options).map(key => {
                    const value = this.product.options[key].name || this.product.options[key]
                    return key !== 'price' && value ? `<b>${key}:</b> ${value} <br>` : ''
                }, '').reduce((p, n) => p + n, '') : ''}
            `;
        }
  }

}
</script>