'use strict';

import Guest from "./components/Guest.vue";

export default class {

    constructor() {

        Vue.component('guest', Guest);

      }
}
