<template>
    <div>
        <h1 class="h2" v-text="title"></h1>
        <div class="row" v-if="empty">
            <div class="col-sm-8 blackBar">
                <div class="title">
                    Ihr Warenkorb ist leer.
                </div>
            </div>
            <div class="col-sm-4 blackBar">
                <a :href="link" class="btn active">Zur Produksuche</a>
            </div>
        </div>
        <div class="table-wrap" v-else>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Artikelbezeichnung</th>
                        <th scope="col" :class="{'text-center': readonly}">Menge</th>
                        <th scope="col" v-if="!readonly">Entfernen</th>
                        <th scope="col" style="width:110px;">Preis</th>
                    </tr>
                </thead>
                <tbody>
                    <cart-row v-for="item in items" :key="item.rowId" :item="item" :readonly="readonly"></cart-row>
                    <tr>
                        <td scope="row">
                            <div class="form-group">
                                <template v-if="readonly">
                                    {{ comment }}
                                </template>
                                <textarea v-else class="form-control" v-model="userComment" name="user_comment" placeholder="Haben Sie einen besonderen Wunsch?"></textarea>
                            </div>

                            <div class="form-group">
                                <template v-if="readonly">
                                    {{ delivery }}
                                </template>
                                <textarea v-else class="form-control" v-model="deliveryDate" name="delivery_date" placeholder="Geben Sie hier den Liefertermin bekannt."></textarea>
                            </div>
                        </td>
                        <td :colspan="totalsSpan" class="sumTextCell">
                            <div class="sumText">Nettobetrag</div>
                            <div class="sumText">MwSt.</div>
                            <div class="sumTextLineTop" v-if="data.voucher.discount">Gutschein Name</div>
                            <div class="sumText" v-if="data.voucher.discount">Gutschein</div>
                            <div class="sumText" v-if="data.voucher.discount">Rabatt</div>
                            <div class="sumTextLineTop"><b>Bruttobetrag</b></div>
                            <div class="sumTextLineTop" v-if="orderQtyDiscount > 0"><b>Rabatt (5%)</b></div>
                            <div class="sumText">Versandkosten <span>(inkl. MwSt.)</span></div>
                            <div class="sumTextLineTop"><b>Gesamtbetrag</b></div>
                        </td>
                        <td class="sumValueCell">
                            <div class="sumValue">{{ subtotal }}&nbsp;€</div>
                            <div class="sumValue">{{ tax }}&nbsp;€</div>
                            <div class="sumValueLineTop" v-if="data.voucher.discount">{{ data.voucher.name }}</div>
                            <div class="sumValue" v-if="data.voucher.discount">{{ data.voucher.discount }}&nbsp;%</div>
                            <div class="sumValue" v-if="data.voucher.discount">-{{ discountValue }}&nbsp;€</div>
                            <div class="sumValueLineTop"><b>{{ subtotalVat }}&nbsp;€</b></div>
                            <div class="sumValueLineTop" v-if="orderQtyDiscount > 0"><b>{{ orderQtyDiscountValue }}&nbsp;€</b></div>
                            <div class="sumValue">{{ shippingCost }}&nbsp;€</div>
                            <div class="sumValueLineTop"><b>{{ total }}&nbsp;€</b></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <section id="payment-types" v-if="(!readonly || voucherCode) && false">
            <div class="row">
                <div class="col-sm-12 blackBar"><div class="title">Gutschein Code</div></div>
            </div>
            <div class="row">
                <div class="row">
                    <div class="col-sm-6">
                        <div>
                            <div class="form-group">
                                <div v-if="readonly" class="voucherCode">
                                    {{ voucherCode }}
                                </div>
                                <input v-else class="form-control" id="voucherCode" type="text" name="voucherCode" placeholder="Geben Sie ihren Gutscheincode ein" v-model="voucherCode" @keyup.enter="addVoucher" />
                                <div class="help-block" v-if="voucherCodeError">
                                    <strong>{{ voucherCodeError }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <button type="button" class="btn btn-dark" @click="addVoucher" v-if="!readonly">anwenden</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
  
    props: {
        title: {
            default: 'Warenkorb'
        },
        readonly: {
            default: false
        },
        comment: {
            default: ''
        },
        delivery: {
            default: ''
        },
        user: {
            default: null
        }
    },

    data() {
            return {
                initialized: false,
                userComment: '',
                deliveryDate: '',
                voucherCode: '',
                voucherCodeError: "",
                items: {},
                data: {
                    tax: '0',
                    total: '0',
                    shippingCost: '0',
                    voucher: "",
                    subtotal: '0',
                    subtotalVat: '0',
                    discountValue: '0',
                    subtotalVatDiscount: '0',
                    fullPrice: '0'
                }
            }
    },

    created() {
        App.event.listen('cart.data.loaded', async (response) => {
            this.items = response.items;
            this.data  = response.data;
            this.voucherCode  = response.data.voucher.code;
            this.initialized = true;
            this.$emit('onLoad', this.items);
        });

        App.event.listen('cart.updated', async (response) => {
            this.items = response.items,
            this.data = response.data
        });

        App.event.listen('shipping.updated', zone => {
            this.data.shippingCost = zone.price
        });

    },

    computed: {

        subtotal() {
            return parseFloat(this.data.subtotal).toFixed(2).replace('.', ',');
        },

        tax() {
            return parseFloat(this.data.tax).toFixed(2).replace('.', ',');
        },

        subtotalVat() {
            this.data.subtotalVat = parseFloat(this.data.subtotal) + parseFloat(this.data.tax);
            return parseFloat(this.data.subtotalVat).toFixed(2).replace('.', ',');
        },

        discountValue() {        
            this.data.discountValue = parseFloat(this.data.fullPrice) - parseFloat(this.data.subtotal);
            this.data.discountValue = parseFloat(this.data.discountValue).toFixed(2)*1.20;
            return parseFloat(this.data.discountValue).toFixed(2).replace('.', ',');
        },

        subtotalVatDiscount() {
            this.data.subtotalVatDiscount = parseFloat(this.data.subtotalVat) - parseFloat(this.data.discountValue);
            return parseFloat(this.data.subtotalVatDiscount).toFixed(2).replace('.', ',');
        },

        orderQtyDiscount() {
            return this.user && this.user.qualifiesForDiscount ? 5 : 0
        },

        orderQtyDiscountValue() {
            return Math.round(
                parseFloat(this.subtotalVat.replace(',', '.')) * (1 - (this.orderQtyDiscount / 100)) * 100
            ) / 100;
        },

        shippingCost() {
            if(this.data.shippingCost != 0) {
                return this.data.shippingCost.toFixed(2).replace('.', ',');
            } else {
                return 0;
            }
            
        },

        total() { 
            let total = this.data.total;

            if (this.data.subtotalVatDiscount) {
                total = (this.data.shippingCost != 0)
                    ? parseFloat(this.data.subtotalVatDiscount) + parseFloat(this.data.shippingCost)
                    : parseFloat(this.data.subtotalVatDiscount);
            } else if (this.orderQtyDiscount > 0) {
                total = this.orderQtyDiscountValue + parseFloat(this.data.shippingCost);
            } else {
                total = parseFloat(this.data.total) + parseFloat(this.data.shippingCost);
            }
            
            return parseFloat(total).toFixed(2).replace('.', ',');
        },

        link() {
            return App.url;
        },

        empty() {
            return this.initialized && Object.keys(this.items).length === 0;
        },

        totalsSpan() {
            return this.readonly ? 1 : 2;
        }
    },

    methods: {
        addVoucher() {
            new Form({voucherCode: this.voucherCode})
                .post('/api/cart/voucherAdd')
                .then((response) => {
                    if(response.error) {
                        this.voucherCodeError = response.errorText;
                        this.items = response.items;
                        this.data  = response.data;
                    } else {
                        this.voucherCodeError = "";
                        this.items = response.items;
                        this.data  = response.data;
                    }
            });
        }
    }

}
</script>
