'use strict';

import Cart            from "./components/cart";
import Guest           from "./components/guest";
import Checkout        from './components/Checkout.vue';
import UserHandler     from "./components/user-handler";
import PaymentType     from "./components/payment-type";
import ShippingType    from "./components/shipping-type";
import ShippingAddress from "./components/shipping-address";

export default class {

    constructor() {
        // Register components

        this.cart = new Cart();
        this.guest = new Guest();
        this.paymentType = new PaymentType();
        this.shippingType = new ShippingType();
        this.shippingAddress = new ShippingAddress();
        this.shippingAddress = new ShippingAddress();
        this.userHandler = new UserHandler();

        Vue.component('checkout', Checkout);

    }
}
