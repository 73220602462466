'use strict';

import CartSidebar from './components/CartSidebar.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('cart-sidebar', CartSidebar);

    }
}
